<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : ''"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : ''"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="分账商户名称/编号"
              :no-data-text="divisionData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in divisionData"
                :key="item.allocMchId"
                :label="item.allocMchName + ' | ' + item.allocMchId"
                :value="item.allocMchId"
              >
              </el-option>
            </el-select> 
          </div>
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in mchDrop"
                :key="i"
                :label="v.mchName"
                :value="v.mchId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>分账状态</i>
            <el-select
              v-model="query.allocStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="分账处理中" value="PROCESSING"></el-option>
              <el-option label="分账成功" value="SUCCESS"></el-option>
              <el-option label="分账失败" value="FAIL"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>分账类型</i>
            <el-select
              v-model="query.allocFundType"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="实时分账" value="11"></el-option>
              <el-option label="延迟分账" value="12"></el-option>
              <el-option label="多次分账" value="13"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>是否担保</i>
            <el-select
              v-model="query.isGuarantee"
              placeholder="全部"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="2"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              placeholder="创建时间"
              style="width: 130px;"
            >
              <el-option label="创建时间" value="CREATE"></el-option>
              <el-option label="交易时间" value="TRADE"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button type="primary" class="searchBt" @click="getLists"
              >查询</el-button
            >
            <el-button
              v-auth="'TRADE:ALLOC:ORDERS/EXPORT'"
              type="primary"
              class="outputBt"
              @click="outputShow"
              >导出</el-button
            >
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box">
          <p><i>交易笔数（笔）</i></p>
          <p>
            <b>{{ tradeNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>平台商户分账金额（元）</i></p>
          <p>
            <b>{{ (mchTradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>分账商户分账金额（元）</i></p>
          <p>
            <b>{{ (allocMchTradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>分账总额（元）</i></p>
          <p>
            <b>{{ (tradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>分账订单号</p>
              <p>分账流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td style="width: 80px">
              <p>分账状态</p>
              <p>分账类型</p>
            </td>
            <td>
              <p>分账金额(元)</p>
            </td>
            <td>
              <p>是否担保</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.mchAllocOrderNo }}</p>
              <p>{{ v.plfAllocOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ allocStatus[v.allocStatus] }}</p>
              <p>{{ allocFundType[v.allocFundType] }}</p>
            </td>
            <td>
              <p>{{ (v.allocAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.guaranteeStatus ? "是" : "否" }}</p>
            </td>
            <td style="padding-right: 10px">
              <p>
                <a
                  v-auth="'TRADE:ALLOC:ORDERS/DETAIL'"
                  href="javascript:;"
                  @click="tabShow(v)"
                  class="lianjie"
                  >详情</a
                >
              </p>
              <p>
                <i
                  v-auth="'TRADE:ALLOC:ORDERS/SYNC'"
                  class="lianjie"
                  @click="getSync(v.allocOrderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'TRADE:ALLOC:ORDERS/NOTIFY'"
                  v-if="v.allocStatus != 'PROCESSING'"
                  @click="getNotify(v.allocOrderId)"
                  class="lianjie"
                  style="padding-left: 10px"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>退款订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              placeholder=""
              style="width: 170px; margin-right: 20px"
            >
              <el-option value="mchOrderNo" label="商户订单号"></el-option>
              <el-option value="plfOrderNo" label="交易流水号"></el-option>
              <el-option value="mchAllocOrderNo" label="分账订单号"></el-option>
              <el-option value="plfAllocOrderNo" label="分账流水号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width: calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>分账订单号</p>
              <p>分账流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td style="width: 80px">
              <p>分账状态</p>
              <p>分账类型</p>
            </td>
            <td>
              <p>分账金额(元)</p>
            </td>
            <td>
              <p>是否担保</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.mchAllocOrderNo }}</p>
              <p>{{ v.plfAllocOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ allocStatus[v.allocStatus] }}</p>
              <p>{{ allocFundType[v.allocFundType] }}</p>
            </td>
            <td>
              <p>{{ (v.allocAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.guaranteeStatus ? "是" : "否" }}</p>
            </td>
            <td style="padding-right: 10px">
              <p>
                <a href="javascript:;" @click="tabShow(v)" class="lianjie"
                  >详情</a
                >
              </p>
              <p>
                <i
                  v-auth="'TRADE:ALLOC:ORDERS/SYNC'"
                  class="lianjie"
                  @click="getSync(v.allocOrderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'TRADE:ALLOC:ORDERS/NOTIFY'"
                  v-if="v.allocStatus != 'PROCESSING'"
                  @click="getNotify(v.allocOrderId)"
                  class="lianjie"
                  style="padding-left: 10px"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog title="导出交易记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getAllocfundOrders,
  getAllocfundSearch,
  exportAllocfundOrders,
  splitTradeSync,
  splitTradeNotify,
} from "@/api/trading/subaccount.js";
import { payproductsDrop, merchantsDrop , DivisionDrop} from "@/api/common.js";
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    let that = this;
    return {
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        allocStatus: "",
        allocFundType: "",
        mchId: "",
        productId: "",
        keyword: "",
        isGuarantee: "0",
      },
      productDrop: [],
      mchDrop: [],
      totalNum: 0,
      tradeNum: 0,
      tradeAmount: 0,
      totalFee: 0,
      orderAmount: 0,
      mchAccount: 0,
      mchTradeAmount: 0,
      allocMchTradeAmount: 0,
      allocStatus: {
        PROCESSING: "分账处理中",
        FAIL: "分账失败",
        SUCCESS: "分账成功",
      },
      allocFundType: {
        11: "实时分账",
        12: "延迟分账",
        13: "多次分账",
      },
      formData: {
        orderNoType: "mchOrderNo",
        orderNo: "",
      },
      orderNoType: {
        mchOrderNo: "商户订单号",
        plfOrderNo: "交易流水号",
        mchAllocOrderNo: "分账订单号",
        plfAllocOrderNo: "分账流水号",
      },
      tabData: [],
      crumbs: "批量查询",
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType: "", //导出时间类型
      divisionData: [], //分账商户名称/编号可选项
      keywordData:{
        allocMchId:"",
        allocMchName:"",
      },
    };
  },
  computed: {
    ...mapState({
      accountDetail: "trading_account",
    }),
  },
  created() {
    if (this.accountDetail) {
      this.query = this.accountDetail.query;
      this.formData = this.accountDetail.formData,
      this.keywordData = this.accountDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.allocMchId != ""){
      this.query.keyword = this.keywordData.allocMchName + ' | ' + this.keywordData.allocMchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setAccountTrading: "trading_setAccount",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        // this.query.keyword = this.query.keyword.trim();
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.allocMchId != ""){
        data.keyword = this.keywordData.allocMchId;
      }
      getAllocfundOrders(data).then((res) => {
        if (res) {
          if (this.crumbs == "批量查询") {
            this.tabData = res.resultData.orders;
            if (!this.tabData || this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNum = res.resultData.totalNum;
            this.tradeNum = res.resultData.tradeNum;
            this.tradeAmount = res.resultData.tradeAmount;
            this.totalFee = res.resultData.totalFee;
            this.mchAccount = res.resultData.mchAccount;
            this.orderAmount = res.resultData.orderAmount;
            this.allocMchTradeAmount = res.resultData.allocMchTradeAmount;
            this.mchTradeAmount = res.resultData.mchTradeAmount;
          }
        }
      });
    },
    // 获取详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (!params.orderNo) {
        this.$message.error(
          "请输入" + this.orderNoType[this.formData.orderNoType]
        );
        return;
      }
      getAllocfundSearch(params).then((res) => {
        if (res) {
          if (this.crumbs != "批量查询") {
            this.tabData = res.resultData;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
          }
        }
      });
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res) {
          this.productDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportAllocfundOrders(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "分账交易查询.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },
    // 表格点击同步时
    getSync(id, index) {
      // console.log("表格点击同步时", id);
      splitTradeSync(id).then((res) => {
        if (res.resultStatus) {
          this.tabData[index].allocStatus = res.resultData.orderStatus;
          this.$message.success("同步请求成功");
        }
      });
    },
    // 表格单击通知时
    getNotify(id) {
      splitTradeNotify(id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("通知请求成功");
        }
      });
    },
    // 详情
    tabShow(value) {
      this.$router.push({
        name: "account_fzjy_detail",
        query: {
          id: value.allocDetailId,
        },
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.divisionData = [];
      let params = {
        keyword: value,
      };
      DivisionDrop(params).then((res) => {
        if (res.resultStatus) {
          this.divisionData = res.resultData;
          console.log(this.divisionData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.divisionData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let allocMchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.divisionData.filter(e=>e.allocMchId === allocMchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 

    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setAccountTrading(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 63.5%;
}
.con_from .lineP .item i {
  width: 64px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 64px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 64px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 64px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
